.root {
	--content-padding: 15px;

	height: 100%;
	display: flex;
	flex-direction: column;

	& footer {
		position: sticky;
		top: 100%;
		margin-top: auto;
		justify-self: end;
	}

	@media (--md) {
		--content-padding: 30px;
	}
}

.header {
	padding-inline: var(--content-padding);
	padding-top: 30px;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;

	& svg {
		width: 24px;
		height: 24px;
	}

	@media (--md) {
		padding: var(--content-padding);
	}
}

.items {
	padding-inline: var(--content-padding);
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.item {
	--border-color: var(--primary-greige);

	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid var(--border-color);

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}

	& .checkoutItem {
		--border-color: var(--primary-dark-blue-01);
	}
}

.empty {
	margin: auto;
}

.summary {
	box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 5%);
	background-color: var(--primary-white);
	padding-top: 15px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 19px;

	@media (--md) {
		padding-bottom: var(--content-padding);
		gap: 21px;
	}

	& .content {
		padding-inline: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (--md) {
			padding-inline: 40px;
		}
	}

	& .count,
	.empty {
		font-size: 9px;
		color: var(--primary-dark-blue-08);

		@media (--md) {
			font-size: 13px;
		}
	}

	& .price {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.4;

		@media (--md) {
			font-size: 32px;
		}
	}

	.paymentOptions {
		margin-top: var(--space-lg);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: var(--space-xs);

		& .paymentOption {
			height: 12px;
			display: flex;
			align-items: center;

			& .paymentAsset {
				width: auto;
				height: 100%;
			}
		}
	}
}

.shipping {
	margin-inline: 15px;
	background-color: var(--primary-greige);
	padding-inline: 15px;
	padding-top: 16px;
	padding-bottom: 15px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.free {
		background-color: var(--accent-green-005);
	}

	@media (--md) {
		margin-inline: 20px;
		padding-inline: 20px;
		padding-top: 21px;
		padding-bottom: 17px;
	}

	& .shippingTotal {
		display: flex;
		flex-direction: column;
		gap: 8px;

		@media (--md) {
			gap: 10px;
		}
	}

	& .amountLeft {
		flex-basis: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	& .right {
		align-items: flex-end;
	}
}
